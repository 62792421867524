import classes from './CommingSoon.module.css';

export default function CommingSoon(){
    return(
        <div className={classes.CommingSoon}>
            <div>
                <h1>Website Under Construtcion</h1>
                <p>Contact me: bhuvanagirichaitanya@chaitanyacodes.com</p>
            </div>
        </div>
    )
}