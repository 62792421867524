import classes from './MainNav.module.css';

export default function MainNav(){
    return(
        <header className={classes.header}>
            <div className={classes.container}>
                <div className={classes.logo}>{'<'} Chaitanya <span>Codes</span> {'/>'}</div>
                <ul className={classes.Menu}>
                    <li><a href="javascript:void(0)">Home</a></li>
                    <li><a href="javascript:void(0)">Create Profile</a></li>
                    <li><a href="javascript:void(0)">About me</a></li>
                </ul>
            </div>
        </header>
    )
}