import CommingSoon from './CommingSoon';
import HomePage from './pages/HomePage';

function App() {
  return (
    <>
      <HomePage />
      <CommingSoon />
    </>
  );
}

export default App;
